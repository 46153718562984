import React from 'react'
import styled from 'styled-components'
import Heading from '../common/components/Heading'
import useWindowDimensions from '../common/hooks/useWindowDimensions'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-flow: ${props => props.left ? 'row-reverse' : 'row'};
    align-items: center;
    height: auto;
    background: rgba(125,125,125,0.1);
    overflow: hidden;
    margin: 0 auto;
    padding-left: ${props => props.left ? '0px' : '50px'};
    padding-right: ${props => props.left ? '50px' : '0px'};
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
    z-index: 10;
    &:hover {
        background: rgba(125,125,125,0.3);
    }
    @media (max-width: 990px) {
        margin-bottom: 30px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 30px;
        flex-flow: column;
        height: auto;
    }
`

const ImageContainer = styled.div`
    width: 80%;
    height: 300px;
    border-radius: 25px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 15px 10px #171717;
    background-repeat: no-repeat;
    @media (min-width: 990px) {
        width: 50%;
        height: 300px;
    }
`

const WrapImage = ({left, heading, text, image}) => {
    const {width} = useWindowDimensions()
  return (
    <Container left={left}>
        <ImageContainer image={image} />
        <div style={{width: width >= 990 ? '50%' : '100%', padding: '50px'}}>
            <Heading content={heading} as='h2' style={{
                color: '#fff', 
                fontSize: width >= 990 ? '30px' : '18px', 
                fontWeight: 'bold', 
                marginBottom: '20px',
                textAlign: width >= 990 ? 'left' : 'center'
                }} />
            <p style={{
                color: '#fff', 
                fontSize: width >= 990 ? '20px' : '15px',
                textAlign: width >= 990 ? 'left' : 'center',
                lineHeight: '1.5'
                }}>{text}</p>
        </div>
    </Container>
  )
}

export default WrapImage