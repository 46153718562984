
export const images = [
  {
    src: "https://i.imgur.com/ZsXs5co.jpeg",
    original: "https://i.imgur.com/ZsXs5co.jpeg",
    width: 320,
    height: 174,
    caption: "Audi A3",
  },
  {
    src: "https://i.imgur.com/Vd8B5Cw.jpeg",
    original: "https://i.imgur.com/Vd8B5Cw.jpeg",
    width: 320,
    height: 150,
    caption: "Ninja",
  },
  {
    src: "https://i.imgur.com/iScoy6F.jpeg",
    original: "https://i.imgur.com/iScoy6F.jpeg",
    width: 320,
    height: 150,
    caption: "Audi A3",
  },
  {
    src: "https://i.imgur.com/1ryucsq.jpeg",
    original: "https://i.imgur.com/1ryucsq.jpeg",
    width: 320,
    height: 213,
    caption: "Golf R",
  },
  {
    src: "https://i.imgur.com/3HuIOhy.jpeg",
    original: "https://i.imgur.com/3HuIOhy.jpeg",
    width: 240,
    height: 320,
    caption: "Yaris GR",
  },
  {
    src: "https://i.imgur.com/8UePo1h.jpeg",
    original: "https://i.imgur.com/8UePo1h.jpeg",
    width: 320,
    height: 190,
    caption: "Audi TT",
  },
];