import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/theme/cryptoModern';
import { ResetCSS } from 'common/assets/css/style';

import Sticky from 'react-stickynode';
import Navbar from '../containers/CryptoModern/Navbar';
import Footer from '../containers/CryptoModern/Footer';
import GlobalStyle, {
  BaseWrapper,
  ContentWrapper,
} from '../containers/CryptoModern/base.style';

import {ModalProvider} from "../common/contexts/ModalContext";
import FeatureSectionWrap from '../containers/CryptoModern/FeatureSectionWrap';
import CompareSection from '../containers/CryptoModern/CompareSection';
import { ImageContainer } from '../containers/CryptoModern/TradersSection';
import { WrapItems } from '../common/data/CryptoModern';
import WrapImage from '../components/wrapImage';
import styled from 'styled-components';
import Heading from '../common/components/Heading';
import useWindowDimensions from '../common/hooks/useWindowDimensions';
import { graphql, useStaticQuery } from 'gatsby';

const WrapsContainer = styled.div`
    width: 100%;
    gap: 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 40px;
    @media (min-width: 993px) {
        padding: 0 200px;
    }
`


const Wrap = () => {

  const {width} = useWindowDimensions()
  if (typeof window === `undefined`) {
    return(<></>);
  }
  
  return (
      <ThemeProvider theme={theme}>
        <>
          <ResetCSS />
          <GlobalStyle />
          <div className={"radial"}/>
          <ModalProvider>
            <BaseWrapper>
              <Sticky top={0} innerZ={9999} activeClass="sticky-active">
                <Navbar />
              </Sticky>
              <ContentWrapper id = "wrapper">
              <CompareSection />
                <ImageContainer>
                    <Heading as="h1" content="Il wrap a regola d'arte" style={{
                    marginTop: width >= 990 ? '150px' : '100px', 
                    color: '#fff', 
                    fontSize: width >= 990 ? '60px' : '32px', 
                    textAlign: 'center', 
                    paddingBottom: '50px',
                    zIndex: '10',
                    position: 'relative',
                    }} />
                    <WrapsContainer>
                    {WrapItems.map((item, index) => (
                        <WrapImage key={`wrap-item-${index}`} left={item.left}  heading={item.heading} text={item.text} image={item.image} />
                    ))}</WrapsContainer>
                </ImageContainer>
                
                <FeatureSectionWrap />
                <Footer />
              </ContentWrapper>
            </BaseWrapper>
          </ModalProvider>
        </>
      </ThemeProvider>
  );
}

export default Wrap;

export function Head() {

  const detailsQuery = useStaticQuery(graphql`
  query DefaultSEOQueryWrap {
    site {
      siteMetadata {
        title
        description
        author
        keywords
        image
        url
      }
    }
  }
`);

return (
  <>
    <html lang={'it'} />
    <title>{`Wrap | ${detailsQuery.site.siteMetadata.title}`}</title>
    <meta name="description" content={detailsQuery.site.siteMetadata.metaDescription} />
    <meta property="og:title" content={`${detailsQuery.site.siteMetadata.title} | Wrap`} />
    <meta property="og:description" content={`SpWrap - Il wrap a regola d'arte`} />
    <meta property="og:url" content={detailsQuery.site.siteMetadata.url} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={detailsQuery.site.siteMetadata.image} />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="SP Wrap" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@SP_Wrap" />
    <meta name="twitter:url" content={detailsQuery.site.siteMetadata.url} />
    <meta name="twitter:image" content={detailsQuery.site.siteMetadata.image} />
    <meta name="twitter:creator" content="@SP_Wrap" />
    <meta name="twitter:title" content={detailsQuery.site.siteMetadata.title} />
    <meta name="twitter:description" content={detailsQuery.site.siteMetadata.metaDescription} />
    {detailsQuery.site.siteMetadata.keywords.length > 0 && (
      <meta name="keywords" content={detailsQuery.site.siteMetadata.keywords.join(', ')} />
    )}
  </>
);
}
