import React from 'react';
import Container from '../../../common/components/UI/Container';
import Fade from 'react-reveal/Fade';
import ReactCompareImage from 'react-compare-image';
import FeatureSectionWrapper from '../FeatureSection/featureSection.style';
import styled from 'styled-components';
import Heading from '../../../common/components/Heading';
import useWindowDimensions from '../../../common/hooks/useWindowDimensions';
import Box from '../../../common/components/Box';

const SliderContainer = styled.div`
    position: relative;
    width: 100%;
    height: 650px;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    background-color: #171717;
    border-radius: 25px;
    box-shadow: 0 0 15px 10px #171717;
    @media (max-width: 990px) {
        margin-bottom: 30px;
        height: 350px;
    }
    @media (max-width: 767px) {
        margin-bottom: 20px;
    }
`

const CompareSection = () => {
    const before = 'https://spwrapcontent.s3.eu-central-1.amazonaws.com/Small_White_Window.jpg'
    const after = 'https://spwrapcontent.s3.eu-central-1.amazonaws.com/Small_Blue.jpg'
    const {width} = useWindowDimensions()

  return (

      <FeatureSectionWrapper style={{padding: width >= 990 ?  '0px 150px' : '0px', marginTop: '150px'}} id="yaris">
        <Container mobileGutter width={'75%'} style={{zIndex: 10, background: 'rgba(125,125,125,0.7)'}}>
            <Fade up delay={50} style={{paddingBottom: '10px'}}>
                <SliderContainer>
                    <ReactCompareImage leftImage={before} rightImage={after} />
                </SliderContainer>
                <Fade up delay={100} style={{paddingBottom: '10px'}}>
              <Box className="sectionHeader" style={{position: 'relative', zIndex: 10}}>
                <div style={{
                  marginTop: width >= 1500 ? '-100px' : width >= 1200 ? '-300px' : width >= 992 ? '-250px' : width >= 768 ? '-50px' : '-180px', 
                  display: 'flex', flexDirection: 'column', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  background: 'rgba(8, 8, 11, 0.7)', 
                  width: width >= 768 ? '90%' : '95%',
                  borderRadius: '17px',
                  padding: '10px'}}>
                <Heading as="h1" style={{fontSize: width >= 992 ? '60px' : '30px', fontWeight: 500, color: '#fff', lineHeight: width >= 992 ? '72px' : '36px',whiteSpace: "pre-line", textAlign: 'center'}}
                content={'I Nostri Servizi di Wrapping'}  />
                <Heading as="h2" style={{lineHeight: width >= 992 ? '150%' : '180%', fontSize: width >= 992 ? '24px' : '15px', fontWeight: 500, marginTop: width >= 992 ? '30px' : '0px', width: width >= 992 ? '90%' : '100%'}}
                content={`La nostra azienda offre servizi di wrapping di alta qualità, per una varietà di applicazioni. Che si tratti di un'auto, di un motorino o di un furgone, il nostro team di professionisti esperti è pronto a trasformare il tuo oggetto in un capolavoro unico. Contattaci per un preventivo gratuito e inizia oggi stesso a esplorare le possibilità offerte dal wrapping.`}  />
                </div>
              </Box>
              </Fade>
            </Fade>
        </Container>
      </FeatureSectionWrapper>
  );
};

export default CompareSection;
